import { BaseElement, html, css } from 'Elements';
import { Fetcher, UrlParams,Lang } from 'Utils';

import './edit.js';
import nodeTypes from './types.js';
import config from './config.js';

class Tree extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-family: Calibri;
        }

        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .search sl-input {
          width:100%;
        }

        .search sl-button {
          width:40px;
          height:30px;
        }

        .icons_right {
          display:flex;
          justify-content:end;
          align-items:center;
          width:100%;
        }

        sl-tree {
          background-color: var(--sl-color-neutral-0);
        }

        sl-tree-item {
          --indent-size:15px;
          --indent-guide-width:1px;
        }

        sl-tree-item::part(label) {
          font-family:Calibri;
          font-size:1rem;
          color:var(--text-color);
          width:100%;
          padding-left:20px;
          position:relative;
          line-height:1.5em;
          display: flex;
          justify-content: stretch;
        }

        sl-tree-item span {
          display:block;
          white-space:nowrap;
        }

        sl-tree-item::part(item) {
          font-family:Calibri;
          color:var(--text-color);
          width:100%;
          padding-left:0px;
          position:relative;
        }

        sl-tree-item::part(expand-button) {
          padding:3px;
          padding-right:7px;
        }

        sl-tree-item .icons_left m-icon {
          font-size:18px;
          padding-left:4px;
          padding-right:4px;
          padding-top:2px;
          position:absolute;
          left:-5px;
          top:1px;
        }

        sl-tree-item .node_title {
          min-width:200px;
        }

        sl-tree-item .node_title:hover {
          color:var(--sl-color-primary-500);
        }

        sl-option::part(base) {
          padding-bottom: 2px;
          padding-top: 2px;
          line-height:initial;
        }

        sl-option m-icon {
          font-size: 1.3em;
          position: absolute;
          left: 3px;
          top: 3px;
        }

        .toolbar {
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin-top:2px;
        }

        sl-menu-item {
          padding: 0;
          cursor: pointer;
          font-size:0.2em;
        }

        sl-menu-item::part(checked-icon) {
          display:none;
        }

        sl-menu-item::part(label) {
          padding:0px;
          font-size:0.9em;
          font-family: Calibri;
          line-height:initial;
        }

        .hidden {
          display:none;
        }

        .gray::part(label) {
          opacity:0.6;
        }

        mark {
          background-color: yellow;
        }

        sl-radio-group::part(form-control-label) {
          font-size:1em;
        }

      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            expandAll:'Expand all',
            collapseAll:'Collapse all',
            expand:'Expand',
            collapse:'Collapse',
            add:'Add',
            remove:'Delete',
            edit:'Edit',
          },
        },
        french:{
          translation: {
            expandAll:'Tout déplier',
            collapseAll:'Tout replier',
            expand:'Déplier',
            collapse:'Replier',
            add:'Ajouter',
            remove:'Supprimer',
            edit:'Editer',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.loading = true;
    this.debug = false;
    this.apiEndpoint = config.apiEndpoint;
    this.moveNode = this.moveNode.bind(this);
    this.selectedItem = null;
    this.selectedTreeItem = null;
    this.hasRoot = false;
    this.nodeTypes = nodeTypes;
  }

  async firstUpdated() {
    super.firstUpdated();
    await this.refreshData();
    this.tree = this.shadowRoot.querySelector('sl-tree');
    this.modalConfirmDelete = document.querySelector('#modal-confirm-delete') || this.shadowRoot.querySelector('#modal-confirm-delete');
    this.nodeEdit = this.parentNode.querySelector('classification-node-edit');
    this.installKeyboardHandler();
    this.installUnselectAllClickHandler();
    this.installNodeUpdateHandler();
    this.expanded = [];
    const expNodes = localStorage.getItem(config.localStorageKey);
    if (expNodes) {
      this.expandedNodes = JSON.parse(expNodes);
      this.restoreExpandedNodes();
    }
  }

  expandRootNode() {
    const root = this.shadowRoot.querySelector('sl-tree-item.root');
    if (root) root.expanded = true;
  }


  installUnselectAllClickHandler() {
    this.toolbarContainer = this.shadowRoot.querySelector('.toolbar');
    this.toolbarContainer.addEventListener('click', (event) => {
      if (event.target.className === 'toolbar') {
        // reset selection
        if (this.selectedTreeItem) {
          this.selectedTreeItem.selected = false;
          this.selectedItem = null;
        }
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.uninstallKeyboardHandler();
    this.uninstallNodeUpdateHandler();
  }

  installKeyboardHandler() {
    this.onKeyDown = this.onKeyDown.bind(this);
    if (this.tree) {
      this.tree.addEventListener('keydown', this.onKeyDown);
    }
  }

  uninstallKeyboardHandler() {
    if (this.tree) {
      this.tree.removeEventListener('keydown', this.onKeyDown);
    }
  }

  onKeyDown(ev) {  
    ev.stopPropagation();
    ev.preventDefault();

    if (ev.key === '+') {
      this.toggleCollapseTree({ recursive:false, expand:true, root: this.selectedTreeItem || this.tree });
      this.storeExpandedNodes();
    } else if (ev.key === '-') {
      this.toggleCollapseTree({ recursive:false, expand:false, root: this.selectedTreeItem || this.tree });
      this.storeExpandedNodes();
    } else if (ev.key === '*') {
      this.toggleCollapseTree({ recursive:true, expand:!this.tree.expanded, root: this.tree });
      this.storeExpandedNodes();
    } else if (ev.key === 'Delete') {
      this.showDeleteItemConfirm(ev);
    }
  }

  installNodeUpdateHandler() {
    this.onNodeUpdated = this.onNodeUpdated.bind(this);
    window.addEventListener(config.eventUpdated, this.onNodeUpdated);
  }

  uninstallNodeUpdateHandler() {
    window.removeEventListener(config.eventUpdated, this.onNodeUpdated);
  }

  async storeExpandedNodes() {
    if (!this.tree) return;
    this.expandedNodes = [];
    this.tree.querySelectorAll('sl-tree-item').forEach(treeItem => {
      if (treeItem.expanded) {
        this.expandedNodes.push(treeItem.item._id);
      }
    });

    // store expanded nodes in local storage
    localStorage.setItem(config.localStorageKey, JSON.stringify(this.expandedNodes));
  }

  async restoreExpandedNodes() {
    this.tree = this.shadowRoot.querySelector('sl-tree');
    if (!this.tree) return;
    this.tree.querySelectorAll('sl-tree-item').forEach(async treeItem => {
      treeItem.classList.remove('hidden');
      treeItem.classList.remove('gray');

      // remove <mark> tags
      const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
      if (slot) {
        const nodes = slot.assignedNodes({ flatten: true });
        nodes.forEach(node => {
          if (node.tagName === 'SPAN' && node.className === 'title') {
            node.innerHTML = node.textContent;
          }
        });
      }

      if (this.expandedNodes) {
        treeItem.expanded = this.expandedNodes.includes(treeItem.item._id);
        if (this.selectedItem && treeItem.item._id === this.selectedItem._id) {
          treeItem.selected = true;
          this.selectedTreeItem = treeItem;
          this.selectedItem = treeItem.item;
        }
      }
    });
  }

  async onNodeUpdated(ev) {
    //console.log('onNodeUpdated', ev.detail);
    this.storeExpandedNodes();

    if (ev.detail._id) {
      await this.refreshData();
      await this.updateComplete;
      const qs = `sl-tree-item[data-id="${ev.detail._id}"]`;
      setTimeout(() => {
        const item = this.shadowRoot.querySelector(qs);
        if (item) {
          item.selected = true;
          item.expanded = true;
          this.selectedTreeItem = item;
          this.selectedItem = item.item;
          this.storeExpandedNodes();
        }
      }, 100);
    } else {
      await this.refreshData();
    }

    this.restoreExpandedNodes();
  }

  toggleCollapseTree( opts ) {
    opts.root.expanded = opts.expand;
    let items;
    if (opts.recursive) {
      items = opts.root.querySelectorAll('sl-tree-item');
    } else {
      items = opts.root.querySelectorAll(':scope > sl-tree-item');
    }
    for (const item of items) item.expanded = opts.expand;
  }

  showDeleteItemConfirm(ev) {
    if (ev?.target?.disabled) return;
    if (this.nodeTypes[this.selectedItem.nodeType].kcgroup) {
      this.modalConfirmDelete.querySelector('.warning').innerHTML = `
        <br/>
        <box-styled variant="warning">
          <div style="font-size:0.8em">
            <a href="${KC_PUBLIC_URL}/admin/master/console/#/sysdreamio/groups/${this.selectedItem.kc_id}" target="kc">Le groupe Keycloak associé</a>
          va également être supprimé.<br/>Les utilisateurs vont devenir des "orphelins" sans groupe.
          </div>
        </box-styled>
      `;
    }
    this.modalConfirmDelete.show();
  }

  async deleteItem() {
    this.modalConfirmDelete.loadingButtons();
    const response = await Fetcher.delete(`${this.apiEndpoint}/${this.selectedItem._id}`);
    this.modalConfirmDelete.resetButtons();
    this.modalConfirmDelete.hide();

    if (response?.ok) {
      this.selectedTreeItem.remove();
      this.emptySelection();
    }
  }

  emptySelection() {
    if (this.selectedTreeItem) {
      this.selectedTreeItem.selected = false;
      this.selectedTreeItem = null;
      this.selectedItem = null;
    }
  }

  showContextMenu(event) {
    let treeItem = event.target;
    if (event.target.tagName !== 'SL-TREE-ITEM') {
      treeItem = event.target.closest('sl-tree-item');
    }
    
    this.emptySelection();
    treeItem.selected = true;

    this.selectedTreeItem = treeItem;
    this.selectedItem = treeItem.item;
    
    event.preventDefault(); // Empêche le menu contextuel par défaut
    event.stopPropagation();

    const existingMenu = document.querySelector('.context-menu');
    if (existingMenu) {
      existingMenu.remove();
    }

    const menu = document.createElement('sl-menu');
    menu.className = 'context-menu';
    menu.style.position = 'absolute';
    menu.style.top = `${event.clientY + window.scrollY}px`;
    menu.style.left = `${event.clientX}px`;

    const add = document.createElement('sl-menu-item');
    add.textContent = this._tl('add');

    // create submenu add
    const submenuAdd = document.createElement('sl-menu');
    submenuAdd.slot = 'submenu';
    add.appendChild(submenuAdd);

    const subgroups = {};

    Object.entries(this.nodeTypes).forEach(([id, nodeType]) => {
      if (id === 'root' && this.hasRoot) return;
      if (nodeType.subgroup) {
        if (!subgroups[nodeType.subgroup]) {
          subgroups[nodeType.subgroup] = document.createElement('sl-menu-item');
          subgroups[nodeType.subgroup].textContent = nodeType.subgroup;

          const icon = document.createElement('m-icon');
          icon.name = nodeType.micon;
          icon.slot = 'prefix';
          subgroups[nodeType.subgroup].appendChild(icon);

          const subgroupMenu = document.createElement('sl-menu');
          subgroupMenu.slot = 'submenu';
          subgroups[nodeType.subgroup].appendChild(subgroupMenu);
          submenuAdd.appendChild(subgroups[nodeType.subgroup]);
        }

        const subgroupMenu = subgroups[nodeType.subgroup].querySelector('sl-menu');

        const item = document.createElement('sl-menu-item');
        item.value = id;
        item.textContent = nodeType.name;

        const icon = document.createElement('m-icon');
        icon.name = nodeType.micon;
        icon.slot = 'prefix';
        item.appendChild(icon);

        item.addEventListener('click', () => {
          this.addItem(id);
          menu.remove();
        });

        subgroupMenu.appendChild(item);
      } else {
        const item = document.createElement('sl-menu-item');
        item.value = id;
        item.textContent = nodeType.name;

        const icon = document.createElement('m-icon');
        icon.name = nodeType.micon;
        icon.slot = 'prefix';
        item.appendChild(icon);

        item.addEventListener('click', () => {
          this.addItem(id);
          menu.remove();
        });
        submenuAdd.appendChild(item);
        
      }
    });
    
    const iconAdd = document.createElement('m-icon');
    iconAdd.name = 'add';
    iconAdd.slot = 'prefix';
    add.appendChild(iconAdd);

    const edit = document.createElement('sl-menu-item');
    edit.textContent = this._tl('edit');
    edit.addEventListener('click', () => { this.editItem(); menu.remove(); });

    // edit
    const iconEdit = document.createElement('m-icon');
    iconEdit.name = 'edit';
    iconEdit.slot = 'prefix';
    edit.appendChild(iconEdit);

    const del = document.createElement('sl-menu-item');
    del.textContent = this._tl('remove');
    del.className = 'delete';
    del.disabled = ENV !== 'dev' && this.selectedItem.nodeType === 'root';
    del.addEventListener('click', (ev) => { this.showDeleteItemConfirm(ev); menu.remove(); });

    const iconDel = document.createElement('m-icon');
    iconDel.name = 'delete';
    iconDel.slot = 'prefix';
    del.appendChild(iconDel);

    const divider = document.createElement('sl-divider');

    menu.appendChild(add);
    menu.appendChild(edit);
    menu.appendChild(divider);
    menu.appendChild(del);

    document.body.appendChild(menu);

    // Fermer le menu sur le prochain clic
    const closeMenu = () => {
      menu.remove();
      document.removeEventListener('click', closeMenu);
    };

    document.addEventListener('click', closeMenu);
  }

  propagateAttributes(items) {
    const itemsById = {};
    for (const item of items) {
      itemsById[item._id] = item;
    }

    function mergeAttributes(nodeId, data) {
        const node = data[nodeId];
        const parentId = node.parentId;
        const merged = {};

        // Copy all io* attributes from the current node
        for (const key in node) {
            if (key.startsWith('iop')) {
                merged[key] = node[key];
            }
        }

        // Recursively merge from parent if available
        if (parentId) {
            const parentMerged = mergeAttributes(parentId, data);
            for (const key in parentMerged) {
                if (key.startsWith('io') && merged[key] === '') {
                    merged[key] = parentMerged[key];
                }
            }
        }

        node.permissions_merged = merged;
        return merged;
    }

    for (const nodeId in itemsById) {
      mergeAttributes(nodeId, itemsById);
    }
  }

  async reload() {
    await this.refreshData();
    this.restoreExpandedNodes();
  }

  async refreshData() {
    this.loading = true;
    const response = await Fetcher.get(this.apiEndpoint);
    const items = response.data;

    // look for nodeType "root" and place it at the first position
    const rootIndex = items.findIndex(item => item.nodeType === 'root');
    if (rootIndex !== -1) {
      const root = items.splice(rootIndex, 1);
      items.unshift(root[0]);
      this.hasRoot = true;
    }

    //this.propagateAttributes(items);
    this.items = items;
    this.loading = false;
  }

  getIcon(item) {
    const nodeType = item.nodeType || '';
    const icon = this.nodeTypes[nodeType].micon || '';
    if (icon) {
      return html`<m-icon name="${icon}" color="${item.color}"></m-icon>`;
    } else {
      this._log.warn('No icon for nodeType', nodeType);
      return '';
    }
  }

  handleDragStart(event, item) {
    event.stopPropagation();

    this.draggedItem = item;
    this.draggedElement = event.currentTarget;
          
    const d = this.floatingDiv = document.createElement('div');
    d.className = 'dragged';

    const i = document.createElement('m-icon');
    i.name = this.getIcon(item);
    i.setAttribute('nogradient', '');
    //i.style.zoom = '75%';
    i.style.marginRight = '5px';
    d.appendChild(i);

    const s = document.createElement('span');
    s.textContent = this.getNodeTitle(item);
    s.style.paddingTop = '2px';
    s.style.paddingRight = '2px';
    d.appendChild(s);
    
    document.body.appendChild(this.floatingDiv);
    event.dataTransfer.effectAllowed = 'move';
    event.target.addEventListener('dragend', this.handleDragEnd.bind(this));

    const img = new Image();
    img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // Image transparente de 1x1 pixel
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  handleDragEnd(event) {
    //console.log('registrytree handleUserDragEnd');
    event.target.removeEventListener('dragend', this.handleDragEnd);
    event.target.classList.remove('hide-during-drag');
    this.floatingDiv.remove();
    this.draggedItem = null;
    this.draggedElement = null;
  }

  isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node instanceof ShadowRoot ? node.host : node.parentNode;
    }
    return false;
  }

  showDropForbidden(ev) {
    if (this.floatingDiv) {
      this.floatingDiv.classList.remove('allowed');
      this.floatingDiv.classList.add('forbidden');
      //ev.dataTransfer.setDragImage(this.floatingDiv, 0, 0);
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'none';
    this.dropAllowed = false;
  }

  showDropAllowed(ev) {
    if (this.floatingDiv) {
      this.floatingDiv.classList.remove('forbidden');
      this.floatingDiv.classList.add('allowed');
      //ev.dataTransfer.setDragImage(this.floatingDiv, 0, 0);
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = 'move';
    this.dropAllowed = true;
  }

  handleDragOver(event) {
    event.preventDefault(); // Nécessaire pour permettre le dépôt

    if (!this.floatingDiv) {
      this.floatingDiv = document.body.querySelector('#dragImage');
      //console.log('dragImage', this.floatingDiv);
    }

    if (this.floatingDiv.id !== 'dragImage') {
      this.floatingDiv.style.left = `${event.clientX - this.floatingDiv.offsetWidth/2}px`; // +10 pour un petit décalage
      this.floatingDiv.style.top = `${event.clientY + 10}px`;
    }
    //event.dataTransfer.setDragImage(this.floatingDiv, 0, 0);

    let target = event.target;

    if (target.tagName !== 'SPAN') {
      this.showDropForbidden(event);
      return;
    }

    while (target && target.tagName !== 'SL-TREE-ITEM') {
      target = target.parentElement;
    }

    const draggedItem = this.draggedElement?.item;

    if (draggedItem) {
      // avoid drag on myself
      if (draggedItem._id !== target.item._id) {
        // avoid drag on parent
        if (draggedItem.parentId !== target.item._id) {
          // avoid drag on children
          if (!this.isDescendant(this.draggedElement, target)) {
            // avoid drag on nodeType not accepting children
            if (this.nodeTypes[target.item.nodeType]?.noChilds) {
              this.showDropForbidden(event);
              return;
            }

            // allow drop
            this.showDropAllowed(event);
            return;
          }
        }
      }
    } else {
      // draggedItem is coming from keycloak-browser
      if (!this.nodeTypes[target.item.nodeType].noChilds) {
        this.showDropAllowed(event);
        return;
      }
    }

    this.showDropForbidden(event);
  }

  handleDrop(event, targetItem) {
    this.floatingDiv.remove();
    this.floatingDiv = null;

    this._log.debug('handleDrop', event, targetItem);
    if (this.dropAllowed === false) return;
    if (event.target.tagName !== 'SPAN') return;
    event.preventDefault();
    event.stopPropagation();
    
    if (this.draggedItem) {
      this.showConfirmModalMove(this.draggedItem, targetItem);
    } else {
      this.showConfirmModalMoveKc(event.target, targetItem);
    }
  }

  showConfirmModalMove(draggedItem, targetItem) {
    this.modalConfirmMove = this.modalConfirmMove || document.querySelector('#modal-confirm-move') || this.shadowRoot.querySelector('#modal-confirm-move');
    this.modalConfirmMove.show();
    this.modalConfirmMove.querySelector('#dragged').innerHTML = this.getNodeTitle(draggedItem);
    this.modalConfirmMove.querySelector('#targeted').innerHTML = this.getNodeTitle(targetItem);

    this.modalConfirmMove.draggedItem = draggedItem;
    this.modalConfirmMove.targetItem = targetItem;
  }

  async moveNode() {
    this.modalConfirmMove.loadingButtons();
    this.updateTreeStructure(this.modalConfirmMove.draggedItem, this.modalConfirmMove.targetItem);
    this.modalConfirmMove.resetButtons();
    this.modalConfirmMove.hide();
    this.requestUpdate();
  }

  async updateTreeStructure(movedItem, newParent) {
    const itemIndex = this.items.findIndex(item => item._id === movedItem._id);
    if (itemIndex !== -1) {
      this.items[itemIndex].parentId = newParent._id;
    }

    await Fetcher.put(`${this.apiEndpoint}/${movedItem._id}`, { parentId:newParent._id });
  }

  async addItem(id) {
    UrlParams.clean();
    this.nodeEdit.parent = this;
    this.nodeEdit.item = null;
    await this.nodeEdit.updateComplete;
    this.nodeEdit.item = {
      nodeType:id,
      parentId:this.selectedItem ? this.selectedItem._id : null
    }
  }

  async editItem(ev) {
    if (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      if (ev.target?.tagName === 'SL-TREE-ITEM') return;
    }
    this.nodeEdit.parent = this;
    this.nodeEdit.item = null;
    await this.nodeEdit.updateComplete;
    this.nodeEdit.item = this.selectedItem;
  }

  async updateCollapseButton() {
    const textEl = this.buttonToggleCollapse.querySelector('div');
    if (this.selectedTreeItem) {
      textEl.innerHTML = this.selectedTreeItem.expanded ? this._tl('collapse') : this._tl('expand');
    } else {
      textEl.innerHTML = this.tree.expanded ? this._tl('collapseAll') : this._tl('expandAll');
      this.buttonToggleCollapse.querySelector('m-icon').name = this.tree.expanded ? 'unfold_less' : 'unfold_more';
    }
  }

  onTreeSelectionChange(ev) {
    this.selectedTreeItem = ev.detail.selection[0];
    this.selectedItem = this.selectedTreeItem.item;
    if (!this.selectedTreeItem.expanded) {
      this.selectedTreeItem.expanded = true;
    }
    //this.updateCollapseButton();
  }

  renderLoading() {
    if (this.loading) {
      return html`<sl-progress-bar indeterminate></sl-progress-bar>`;
    }
  }

  getNodeTitle(item) {
    //this._log.debug('getNodeTitle', item);
    const nodeType = this.nodeTypes[item.nodeType];
    if (!nodeType) {
      // item.email is coming from this.kcBrowserDraggedItem
      return item.email || item.name;
    }

    let fields = nodeType.nodeTitle;
    if (!fields) {
      return item.name || nodeType.name;
    }

    if (typeof fields === 'string') fields = [fields];

    let str = fields.map(field => {
      return Lang.lookup(item, field);
    });

    // remove undefined values
    str = str.filter(Boolean);
    str = str.join(' ');
    //str = this.sanitizeHTML(str);
    return str;
  }

  sanitizeHTML(str) {
    if (typeof str !== 'string') return str;
    return str.replace(/&/g, '&amp;')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/"/g, '&quot;')
              .replace(/'/g, '&#039;')
              .replace(/`/g, '&#x60;');
  }

  renderTreeItems(item, items) {
    const childrens = items.filter(child => child.parentId === item._id);

    // order by nodeType and name, nodeType having noChilds last
    childrens.sort((a, b) => {
      if (nodeTypes[a.nodeType].noChilds) {
        return 1;
      } else if (!nodeTypes[a.nodeType].noChilds) {
        return -1;
      } else {
        if (a.nodeType === b.nodeType) {
          return this.getNodeTitle(a).localeCompare(this.getNodeTitle(b));
        }
      }
    });

    // expanded
    return html`
      <sl-tree-item 
        draggable="${item.nodeType !== 'root'}"
        data-id="${item._id}"
        data-name="${item.name}"
        .item="${item}"
        title="${item._id}"
        value="${this.getNodeTitle(item)}"
        class="${item.css || ''}"
        
        @contextmenu="${(event) => this.showContextMenu(event, item)}"
        @dragstart="${(event) => this.handleDragStart(event, item)}"
        @dragover="${(event) => this.handleDragOver(event, item)}"
        @drop="${(event) => this.handleDrop(event, item)}"
        @dblclick="${(ev) => this.editItem(ev)}"
        @sl-after-expand="${() => this.storeExpandedNodes()}"
        @sl-after-collapse="${() => this.storeExpandedNodes()}"
      >
        <span class="icons_left" title="${item.name} (${item.nodeType})">${this.getIcon(item)}</span>
        <span class="node_title" title="${item.name} (${item.nodeType})">${this.getNodeTitle(item)}</span>
        <span class="icons_right"></span>
        ${childrens.length > 0 ? html`${childrens.map(child => this.renderTreeItems(child, items))}` : ''}
      </sl-tree-item>
    `;
  }

  renderTree() {
    if (!this.loading) {
      if (this.items?.length) {
        
        const rootItems = this.items ? this.items.filter(item => !item.parentId || !this.items.some(i => i._id === item.parentId)) : [];

        return html`
          <sl-tree selection="single" @sl-selection-change="${this.onTreeSelectionChange}">
            ${rootItems.map(item => this.renderTreeItems(item, this.items))}
          </sl-tree>
        `
      } else {
        return html`<div>C'est vide !</div>`;
      }
    }
  }

  handleSearchInput(ev) {
    const target = ev.target;
    clearTimeout(this.searchTimeout);   
    this.searchTimeout = setTimeout(() => {
      this.q = target.value.toLowerCase().trim();

      if (!this.q) {
        this.restoreExpandedNodes();
        return;
      }
      
      if (!this.tree) return;

      const items = this.tree.querySelectorAll('sl-tree-item');
      if (!items) return;

      items.forEach(treeItem => {
        let found = false;
        
        //console.log('analyze', treeItem.item.name || treeItem.item.firstname);

        // loop trought all item attribut values to find a match
        for (const value of Object.values(treeItem.item)) {
          //console.log('==> look', this.q,'in', value);
          // si la valeur est un objet, parcours l'ensemble des sous objets
          if (value !== null && typeof value === 'object') {
            for (const subValue of Object.values(value)) {
              if (subValue && subValue.toString().toLowerCase().indexOf(this.q) >= 0) {
                //console.log('==> found', this.q,'in', treeItem);
                found = true;
                break;
              }
            }
          } else {
            if (value && value.toString().toLowerCase().indexOf(this.q) >= 0) {
              //console.log('==> found', this.q,'in', treeItem);
              found = true;
              break;
            }
          }
        }

        if (found) {
          // visible
          treeItem.classList.remove('hidden');
          treeItem.classList.remove('gray');
          // highlight matching part
          const slot = treeItem.shadowRoot.querySelector('slot[part="label"]');
          if (slot) {
            const nodes = slot.assignedNodes({ flatten: true });
            nodes.forEach(node => {
              if (node.tagName === 'SPAN' && node.className === 'title') {
                const originalText = node.textContent;
                const regex = new RegExp(`(${this.q})`, 'gi');
                const highlightedText = originalText.replace(regex, '<mark>$1</mark>');
                node.innerHTML = highlightedText;
              }
            });
          }

          while (treeItem.parentElement) {
            treeItem = treeItem.parentElement;
            treeItem.expanded = true;
            treeItem.classList.remove('hidden');
            treeItem.classList.add('gray');
          }
        } else {
          //console.log('=> hide');
          treeItem.classList.add('hidden');
        }

      });
    }, 500);

  }

  renderToolbar() {
    return html`
      <div class="search">
        <sl-input size="small" name="eq" @sl-input="${this.handleSearchInput}" clearable .value="${this.q || ''}">
          <m-icon nogradient name="search" slot="suffix"></m-icon>
        </sl-input>
      </div>
      <div class="toolbar">
        <div>
          <!--
          <sl-button size="small" name="toggleCollapse" @click="${this.toggleCollapse}">
            <div>${this._tl('expandAll')}</div>
            <m-icon slot="prefix" name="unfold_more" size="big" title="${this._tl('expandAll')}">
          </sl-button>
          -->
        </div>
        <div>
          <sl-dropdown>
            <sl-button slot="trigger" size="small">
              <div>${this._tl('add')}</div>
              <m-icon slot="prefix" name="add" size="big" title="${this._tl('add')}">
            </sl-button>
            <sl-menu size="small">
              ${this.renderAllowedNodeTypesMenu()}
            </sl-menu>
          </sl-dropdown>
        </div>
      </div>
    `
  }

  renderAllowedNodeTypesMenu() {
    const renderedSubgroups = {};  // Pour suivre les sous-groupes déjà rendus

    return Object.entries(this.nodeTypes).map(([id, nodeType]) => {
      if (nodeType.subgroup) {
        // Vérifie si le sous-groupe a déjà été rendu
        if (!renderedSubgroups[nodeType.subgroup]) {
          renderedSubgroups[nodeType.subgroup] = [];
          return html`
            <sl-menu-item>
              <m-icon slot="prefix" name="${nodeType.micon}"></m-icon>
              ${nodeType.subgroup}
              <sl-menu slot="submenu">
                ${Object.entries(this.nodeTypes).map(([subId, subNodeType]) => {
                  if (subNodeType.subgroup === nodeType.subgroup) {
                    return html`
                      <sl-menu-item value="${subId}" @click="${() => this.addItem(subId)}">
                        <m-icon slot="prefix" name="${subNodeType.micon}" nogradient></m-icon>
                        ${subNodeType.name}
                      </sl-menu-item>
                    `;
                  }
                })}
              </sl-menu>
            </sl-menu-item>
          `;
        }
      } else {
        // Si le nodeType n'a pas de sous-groupe, l'ajoute directement
        return html`
          <sl-menu-item value="${id}" @click="${() => this.addItem(id)}">
            <m-icon slot="prefix" name="${nodeType.micon}"></m-icon>
            ${nodeType.name}
          </sl-menu-item>
        `;
      }
    });
  }

  render() {
    return html`
      ${this.renderToolbar()}<br/>     
      ${this.renderLoading()}
      ${this.renderTree()}

      <modal-dialog id="modal-confirm-move" label="Confirmation">
        <br/>  
        <p>
          Etes vous sûr(e) de vouloir déplacer l'élément <br/>
          &laquo;&nbsp;<span style="font-weight:bold;" id="dragged"></span>&nbsp;&raquo; dans 
          &laquo;&nbsp;<span style="font-weight:bold;" id="targeted"></span>&nbsp;&raquo; .
        </p>
        <br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.moveNode}">Déplacer</sl-button>
      </modal-dialog>

      <modal-dialog id="modal-confirm-delete" label="Confirmation">
        <br/>  
        <p>
          Etes vous sûr(e) de vouloir supprimer l'élément ?
          <br/>
          <span class="warning"></span>
        </p>
        <br/>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="danger" @click="${this.deleteItem}">Supprimer</sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('classification-tree', Tree);