import { html, css } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import { Lang } from 'Utils';

import config from './config.js';

class ClassificationEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        label {
          font-size:0.9em;
          padding-left:5px;
        }

        .padding {
          padding-left:5px;
        }

        box-styled[variant="warning"] {
          font-size:0.9em;
        }

        pre {
          font-size:0.6em;
        }
      `
    ]
  }

  static get properties() {
    return {
      filter: { type: String }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Classifications',
          },
        },
        french:{
          translation: {
            title:'Classifications',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.itemTitleField = config.itemTitleField;
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this._logEnable = false;
  }

  getTranslatePayload() {
    const payload = {
      title: Lang.lookup(this.item, 'title'),
      desc: Lang.lookup(this.item, 'desc'),
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('title', 'en').value = ev.detail.title || '';
    this._lookupField('desc', 'en').value = ev.detail.desc  || '';
    this.compareFields();
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  editorUpdated() {
    this.compareFields();
  }

  render() {    
    if (!this.item) return '';

    // FIXME: color label picker is not shown, fixed, in webawesome 3, but not in shoelace
    // https://github.com/shoelace-style/shoelace/issues/1552

    return html`
      <modal-drawer>
        <form>
          <br/>
          <tab-group level="c" class="main" .item=${this.item}>
            <sl-tab slot="nav" panel="general">${this._tl('title')}</sl-tab>

            <sl-tab-panel name="general">
              <tab-group-lg level="f" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                <br/>
                <sl-input size="small" name="title" class="labol" label="Titre"></sl-input><br/>
                <sl-textarea size="small" name="desc" class="labol" label="Description"></sl-textarea><br/>
              </tab-group-lg>
              <sl-color-picker label="Color" class="label" name="color"></sl-color-picker>
            </sl-tab-panel>


          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>
    `;
  }

}

customElements.define('classification-node-edit', ClassificationEdit);