import { Session } from 'Utils';

//const hidden = ENV.match(/prod/) ? 'hidden' : '';
const hidden = 'hidden';

const properties = {
  name:'properties',
  title:'Propriétés <m-icon name="lock"></m-icon>',
  hidden:() => !Session.isBoss(),
  fields:[
    { component:'box-json' },
  ]
}

const definitions = {
  classification:{
    name:'Classification',
    micon:'label',
    nodeTitle:'title',
    tabs:[
      {
        name:'general', 
        title:'Général',
        fields_hidden:[
          { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
        ],
        fields:[
          { name:'color',     component:'sl-color-picker',  label:'Couleur' },
        ],
        fields_lgs:[
          { name:'title',     component:'sl-input',         label:'Titre' },
          { name:'desc',      component:'sl-textarea',      label:'Description' },
        ]
      }
    ]
  },
  subclassification:{
    name:'Sous-classification',
    micon:'sell',
    nodeTitle:'title',
    tabs:[
      {
        name:'general', 
        title:'Général',
        fields_hidden:[
          { name:'nodeType',  component:'sl-input',         label:'Type de noeud', sameDefaultValue: true, css: `${hidden}` },
        ],
        fields:[
          { name:'color',     component:'sl-color-picker',  label:'Couleur' },
        ],
        fields_lgs:[
          { name:'title',     component:'sl-input',         label:'Titre' },
          { name:'desc',      component:'sl-textarea',      label:'Description' },
        ]
      }
    ]
  }
}

for (const key in definitions) {
  definitions[key].tabs?.push({ ...properties })
}


export default definitions;